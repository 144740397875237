<template>
  <div>
    <header-portada-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />
    <BCard id="seccion-content">
      <BCardHeader class="header-df">
        <BCol
          cols="12"
          md="12"
          class="pl-0"
        >
          <BMedia vertical-align="center">
            <template #aside>
              <feather-icon
                size="36"
                icon="LayoutIcon"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              Header Portada
            </span>
            <small class="text-muted">
              Gestiona imágenes de la cabecera de la portada del aplicativo móvil y sitio web.
            </small>
          </BMedia>
        </BCol>
      </BCardHeader>
      <BCard
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <BRow>
            <!-- Per Page -->
            <BCol
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrando</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>registros</label>
            </BCol>

            <!-- Search -->
            <BCol
              cols="12"
              md="8"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  class="ml-50"
                  variant="primary"
                  @click="openForm('new')"
                >
                  <span class="text-nowrap">Agregar</span>
                </b-button>
              </div>
            </BCol>
          </BRow>
        </div>

        <BTable
          ref="refPromocionListTable"
          class="position-relative"
          :items="items"
          responsive
          :fields="fields"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No se encontraron registros"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner
                variant="primary"
                class="align-middle"
              />
              <strong class="ml-1">Cargando...</strong>
            </div>
          </template>
          <template #cell(titulo)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  rounded
                  class="avatar"
                  size="70"
                  variant="primary"
                  :src="
                    data.item.url !== ''
                      ? `${pathMultimedia}/byUrl?isthumb=true&url=${data.item.url}`
                      : '0'
                  "
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.titulo }}
              </span>
              <small
                class="text-muted d-block "
              >
                {{ data.item.subTitulo }}
              </small>
            </b-media>
          </template>

          <template #cell(acciones)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="getEdit(data)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getDelete(data)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell()="data">
            <span class="d-block text-nowrap text-justify">
              {{ data.value }}
            </span>
          </template>
        </BTable>
        <div class="mx-2 mb-2">
          <BRow>
            <BCol
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
            </BCol>
            <!-- Pagination -->
            <BCol
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="page"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </BCol>
          </BRow>
        </div>
      </BCard>
    </BCard>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BButton,
  BAvatar,
  BTable,
  BCol,
  BCard,
  BCardHeader,
  BMedia,
  BRow,
  BPagination,
  BSpinner,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import config from '@/services/config'
import { useNotify } from '@/helpers/toast'
import { showToast, showError } from '@/helpers'
import HeaderPortadaForm from './HeaderPortadaForm.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    HeaderPortadaForm,
    BButton,
    BAvatar,
    BPagination,
    BCol,
    BCard,
    BCardHeader,
    BMedia,
    BRow,
    BTable,
    vSelect,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'titulo',
          label: 'Datos del Header',
        },
        {
          key: 'Acciones',
          label: 'Acciones',
          tdClass: ['text-center', 'bg-white'],
          thClass: ['text-center'],
        },
      ],
      sortBy: null,
    }
  },
  methods: {
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.getHeader()
    },
    showToast,
    showError,
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const page = ref(1)
    const query = ref('')
    const perPage = ref(12)
    const pageOptions = [10, 25, 50, 100]
    const items = ref([])

    const totalItems = ref(0)
    const totalPages = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const totalRows = ref(0)
    const isBusy = ref(true)
    const searchADR = ref([
      {
        text: 'Pendientes',
        value: 'R',
      },
      {
        text: 'Aprobados',
        value: 'A',
      },
      {
        text: 'Desaprobados',
        value: 'D',
      },
    ])
    const selectADR = ref({ text: 'Pendiente', value: 'R' })
    const { pathRepoPI, contextPI } = config
    const pathMultimedia = ref(`${pathRepoPI}${contextPI}/api/v1/multimedia`)

    const isToggleSidebar = ref(false)
    const dataEdit = ref({})
    const formType = ref('')

    const openForm = type => {
      formType.value = type
      isToggleSidebar.value = true
    }

    const getEdit = data => {
      formType.value = 'edit'
      isToggleSidebar.value = true
      dataEdit.value = data.item
    }

    const getHeader = () => {
      isBusy.value = true
      store
        .dispatch('plataforma/DISENO_HEADER_FIND_ALL', {
          seccion: 'PORTADA',
          seccionId: -1,
          tipoHeader: 'IMAGEN',
        })
        .then(response => {
          items.value = response
          totalRows.value = response.length
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
          from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
          to.value = perPage.value * (page.value - 1) + totalRows.value
          of.value = totalItems.value
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const getDelete = data => {
      Vue.swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('plataforma/DISENO_HEADER_DELETE', {
              id: data.item.idDiseno,
            })
            .then(() => {
              getHeader()
              notify('Operación Exitosa', 'Ha Eliminado correctamente el registro.', 'success')
            })
            .catch(error => {
              let errorMessage = 'Intentelo en otro momento.'
              if (error.response) {
                errorMessage = error.response.data.message
              }
              notify('Ha ocurrido un error', errorMessage, 'danger')
            })
        }
      })
    }

    watch([query, page, perPage], () => {
      getHeader()
    })
    getHeader()
    return {
      pathMultimedia,
      page,
      query,
      perPage,
      totalItems,
      totalPages,
      from,
      to,
      of,
      totalRows,
      pageOptions,
      notify,
      getHeader,
      items,
      isBusy,
      searchADR,
      selectADR,
      openForm,
      getEdit,
      getDelete,
      isToggleSidebar,
      dataEdit,
      formType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
