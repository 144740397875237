<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >

        <h6
          class="text-info"
        >
          <feather-icon icon="AlertCircleIcon" />
          <span class="align-middle ml-25">Datos Generales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Título"
          label-for="titulo"
        >
          <b-form-input
            id="titulo"
            v-model="dataRegister.titulo"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <b-form-group
          label="Sub título"
          label-for="subTitulo"
        >
          <b-form-textarea
            id="subTitulo"
            v-model="dataRegister.subTitulo"
            rows="3"
          />
        </b-form-group>

        <b-form-group
          label="Link"
          label-for="link"
        >
          <b-form-input
            id="link"
            v-model="dataRegister.link"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <b-form-group
          label="Orden"
          label-for="orden"
        >
          <b-form-input
            id="orden"
            v-model="dataRegister.orden"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <h6
          class="text-info"
        >
          <feather-icon icon="ImageIcon" />
          <span class="align-middle ml-25">Multimedia</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <!-- Banner -->
        <b-form-group
          label="Banner"
          label-for="banner"
        >
          <b-row class="container">
            <b-col md="4">
              <b-media class="mb-2 text-center">
                <template #aside>
                  <b-avatar
                    ref="previewElBanner"
                    :src="imgBase64Banner"
                    size="100px"
                    rounded=""
                  />
                </template>
              </b-media>
            </b-col>
            <b-col md="8">
              <b-row class="mt-1">
                <b-col md="12">
                  <b-button
                    variant="info"
                    class="btn-block btn-sm"
                    @click="$refs.refInputElBanner.$el.click()"
                  >
                    <b-form-file
                      id="file"
                      ref="refInputElBanner"
                      v-model="fileBanner"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="uploadBanner"
                    />
                    <span class="d-none d-sm-inline">Seleccionar Banner</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="padding-top:10px">
                <b-col md="12">
                  <b-button
                    variant="light"
                    class="btn-block btn-sm"
                    @click="onDeleteBanner"
                  >
                    <span class="d-none d-sm-inline">Eliminar Banner</span>
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormFile, BRow, BCol, BMedia, BAvatar, BFormTextarea,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import config from '@/services/config'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormFile,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const { pathRepoPI, contextPI } = config
    const pathMultimedia = ref(`${pathRepoPI}${contextPI}/api/v1/multimedia`)
    const titleForm = ref('')
    const dataRegister = ref({})
    const imgBase64Banner = ref(null)
    const refInputElBanner = ref(null)
    const fileBanner = ref(null)
    const previewElBanner = ref(null)

    const onDeleteBanner = () => {
      imgBase64Banner.value = null
    }
    const uploadBanner = e => {
      const fileTemp = e.target.files[0]
      fileBanner.value = fileTemp
      imgBase64Banner.value = URL.createObjectURL(fileBanner.value)
    }

    const resetForm = () => {
      dataRegister.value = {}
      imgBase64Banner.value = ''
    }

    const saveForm = async () => {
      let service = 'plataforma/DISENO_HEADER_CREATE'
      dataRegister.value.tipoHeader = 'IMAGEN'
      dataRegister.value.seccion = 'PORTADA'
      dataRegister.value.seccionId = -1
      dataRegister.value.idDestinoTuristico = 0
      dataRegister.value.imagen = (fileBanner.value !== null) ? fileBanner.value : null

      if (props.formType === 'edit') {
        service = 'plataforma/DISENO_HEADER_UPDATE'
      }
      await store.dispatch(service, dataRegister.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          // emit('update:is-toggle-sidebar', false)
        })
    }

    const getLogo = (data, tipo) => {
      if (tipo === 'banner') imgBase64Banner.value = `${pathMultimedia.value}/byUrl?isthumb=true&url=${data.url}`
    }

    const toggleSidebar = val => {
      resetForm()
      emit('update:is-toggle-sidebar', val)
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Header Portada'
      }
      if (props.formType === 'edit') {
        dataRegister.value = props.dataEdit
        if (props.dataEdit.url) getLogo({ url: props.dataEdit.url, isthumb: true }, 'banner')

        titleForm.value = 'Editar Header Portada'
      }
    }

    return {
      resetForm,
      dataRegister,
      saveForm,
      toggleSidebar,
      titleForm,
      onDeleteBanner,
      uploadBanner,
      imgBase64Banner,
      refInputElBanner,
      fileBanner,
      previewElBanner,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 41px);
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.vs__search {
  position: absolute;
}

.vs--open .vs__search {
  position: static;
}

.vs__dropdown-option {
  white-space: normal;
}
</style>
