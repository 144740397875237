<template>
  <div>
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="SmartphoneIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold"> Onboarding</span>
            </div>
          </template>
          <Onboarding />
        </b-tab>
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="LayoutIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold"> Header Portada</span>
            </div>
          </template>
          <HeaderPortada />
        </b-tab>
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="SidebarIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold"> Header Destino</span>
            </div>
          </template>
          <HeaderDestino />
        </b-tab>
        <!--/ payment tab -->
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { showToast, showError } from '@/helpers'
import Onboarding from './Onboarding.vue'
import HeaderPortada from './HeaderPortada.vue'
import HeaderDestino from './HeaderDestino.vue'

export default {
  components: {
    BTabs,
    BTab,
    Onboarding,
    HeaderPortada,
    HeaderDestino,
  },
  data() {
    return {
      faqData: {},
      dataEdit: {},
      isLoad: false,
      idAtractivo: -1,
    }
  },
  methods: {
    showToast,
    showError,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-atractivo-detalle.scss';
</style>
